import {Button, ButtonGroup, Container, Row, Col} from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeEmbed from "./YoutubeEmbed";
import ContactForm from "./ContactForm";

const HeroContent = () => {
    return(
            <Container className="row-content" style={{background: '#ECE3E5' }}>
                <Row className="row-content" style={{marginTop: '3%'}}>
                    <Col xxl='8' xl='8' lg='6' md='6'sm='6'>
                        <Row>
                            <h1  className='text-center' style={{font: 'Diphylleia', fontSize: '1.5em', marginTop: '0.5%'}}>Las Vegas Home Buyers</h1>
                        </Row>
                        <Row>
                            <YoutubeEmbed embedId="ZfzEsiLL_NQ" />
                        </Row>
                            <div className="row-content text-center">
                                <ButtonGroup>
                                    <a href="https://anthonymadaffari.bhhsnv.com/" target="_blank" rel="noopener noreferrer">
                                        <Button className="shadow-5-strong"style={{fontFamily: 'Diphylleia', fontSize: 'small', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                            Sellers
                                        </Button>
                                    </a> 
                                    <a href="https://bhhsnv.findbuyers.com/tonym@bhhsnv.com" target="_blank" rel="noopener noreferrer">
                                        <Button style={{marginLeft: '1rem', fontFamily: 'Diphylleia', fontSize: 'small', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                            Home Value
                                        </Button>
                                    </a>
                                </ButtonGroup>
                            </div>
                        <Row>
                            <a className="text-center" href="https://missionloansretail.com/ginger-lamson/" target="_blank" rel="noopener noreferrer">
                                <img className="responsive" src="https://missionloansretail.com/wp-content/uploads/2024/02/Ginger-Lamson-ad-600x90-1.jpg" alt="Ginger Lamson" style={{width:'600px', height:'90px'}}/>
                            </a>
                        </Row> 
                    </Col>
                    <Col>
                        <Row>
                            <h1 className= "text-center" style={{font: 'Diphylleia', fontSize: '1.5em', marginTop: '1%'}} >Future Home Buyer</h1>
                            <hr/>
                        </Row>
                        <Row>
                            <p className="errorMessage" style={{fontStyle: "italic"}}>* Indicates required field</p>
                        </Row>
                        <Row>
                            <ContactForm/>
                        </Row>
                    </Col>
                </Row>
            </Container> 
                                   
            
         
    );
};


export default HeroContent;